"use client";

import { useEffect, useRef, useState } from "react";
import styles from "./HorizontalScroll.module.css";
import Image from "next/image";

const HorizontalScroll = () => {
  const [selectedCardIndex, setSelectedCardIndex] = useState(0);
  const [cardIndexes, setCardIndexes] = useState(["1", "2", "3"]);
  const [isVisible, setIsVisible] = useState(false);
  const intervalRef = useRef(null);
  const componentRef = useRef();

  const handleIntersection = (entries) => {
    entries.forEach((entry) => {
      // Update the state based on whether the component is in the viewport
      setIsVisible(entry.isIntersecting);
    });
  };

  useEffect(() => {
    // Create an intersection observer
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };

    // Observe the component
    const observer = new IntersectionObserver(handleIntersection, options);
    observer.observe(componentRef.current);

    // Clean up the observer when the component is unmounted
    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    // Run the function every x seconds if the component is in the viewport
    if (isVisible) {
      intervalRef.current = setInterval(() => {
        next();
      }, 4000);
    } else {
      // Clear the interval if the component is not in the viewport
      clearInterval(intervalRef.current);
    }

    // Clean up the interval when the component is unmounted or visibility changes
    return () => clearInterval(intervalRef.current);
  }, [isVisible]);

  const next = () => {
    setCardIndexes((currentCardIndexes) => {
      const items = [...currentCardIndexes];
      const currentItem = items[0];
      items.shift();
      const returnValue = [...items, currentItem];
      return returnValue;
    });
  };

  return (
    <div className={styles.scrollingArea} ref={componentRef}>
      {cardIndexes.map((num, index) => {
        const distance = index - selectedCardIndex;
        const absDistance = Math.abs(distance);
        const scale = 1 - 0.25 * absDistance;
        const zIndex = 100 - absDistance;
        return (
          <Image
            key={num}
            width={319}
            height={377}
            alt={`Card Photo ${num}`}
            src={`/main/main${num}.jpg`}
            style={{
              position: "absolute",
              marginLeft: "auto",
              borderRadius: "10px",
              float: "right",
              scale: scale,
              transformOrigin: `100% 50%`,
              transform: `translateX(calc(${distance} * 15px))`,
              zIndex: zIndex,
              transition: "transform 0.5s ease, scale 0.5s ease",
            }}
          />
        );
      })}
    </div>
  );
};

export default HorizontalScroll;
